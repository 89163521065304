import { Box } from "@mui/system";
import { ReactElement } from "react";
import { useTranslate } from "react-admin";
import { FrequencyType } from "../../../lib/types";

export function Frequency({
  frequency: fr,
  userWCPM,
  total,
  checked,
}: {
  frequency: FrequencyType;
  userWCPM?: string;
  total?: number;
  checked?: number;
}): ReactElement {
  const translate = useTranslate();

  return (
    <Box>
      <Box component={"span"} sx={{ fontWeight: "bold" }} title={translate("stats.frequency.description")}>
        {translate("stats.frequency.title")}{" "}
      </Box>
      <Box component={"span"}>
        {fr.wcpm ? fr.wcpm + "/" : ""}
        {fr.wcdp ? fr.wcdp + "% :" : ""} {userWCPM !== undefined ? userWCPM + "/" : ""}
        {total !== undefined ? total + "/" : ""}
        {checked !== undefined ? checked : ""}
      </Box>
    </Box>
  );
}
