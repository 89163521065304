import { Box } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { TokenDetailsState } from "../../../features/ui/uiSlice";
import { DefinitionType, WordModelStatsType } from "../../../lib/types";
import { Frequency } from "./Frequency";
import POS from "./POS";
import { useTranslate } from "react-admin";
import { platformHelper } from "../../../app/createStore";

type Props = {
  definition: DefinitionType;
  tokenDetails: TokenDetailsState;
};

export default function Infos({ definition, tokenDetails }: Props): ReactElement {
  const fromLang = useAppSelector((state) => state.userData.user.fromLang);
  const translate = useTranslate();
  const [wordStats, setWordStats] = useState<WordModelStatsType>();
  const [userWCPM, setUserWCPM] = useState<string>("");
  useEffect(() => {
    (async () => {
      const ws = await platformHelper.getWordModelStats(definition.id);
      setWordStats(ws);
      const nbAllSeen = await platformHelper.getNbAllWordsSeen();
      if (ws && typeof nbAllSeen !== undefined) {
        const uWCPM = (ws.nbSeen || 0) / (nbAllSeen / 1_000_000);
        setUserWCPM(uWCPM.toFixed(uWCPM > 9 ? 0 : 2));
      } else {
        console.debug("Somehow the db is returning undefined for", definition, ws, nbAllSeen);
      }
    })();
  }, [definition]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "left" }}>
        {fromLang === "zh-Hans" && (
          <div>
            {definition.hsk?.levels && definition.hsk.levels.length > 0
              ? translate("widgets.popup.hsk_level", { hsk: definition.hsk.levels.join(", ") })
              : translate("widgets.popup.no_hsk")}
          </div>
        )}
        <POS token={tokenDetails.token} />
      </Box>
      <Frequency
        frequency={definition.frequency}
        userWCPM={userWCPM}
        total={wordStats?.nbSeen}
        checked={wordStats?.nbChecked}
      />
    </>
  );
}
